import React, { useState, useRef, useEffect } from 'react';
import { useUTM } from '../contexts/UTMContext';

const DomainInput = ({ onDomainChange }) => {
  const { domain, setDomain, isDomainValid, setIsDomainValid } = useUTM();
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    validateDomain(domain); // Trigger validation whenever domain changes
  }, [domain]);

  const handleChange = (event) => {
    const newDomain = event.target.value.trim();
    setDomain(newDomain);
    validateDomain(newDomain);
  };

  const validateDomain = (newDomain) => {
    if (newDomain && (newDomain.startsWith('http://') || newDomain.startsWith('https://'))) {
      setIsDomainValid(true);
      onDomainChange(newDomain, true);
    } else if (newDomain) {
      setIsDomainValid(false);
      onDomainChange(newDomain, false);
    } else {
      setIsDomainValid(false);
      onDomainChange('', false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    const formattedDomain = domain.replace(/\/+$/, '');
    setDomain(formattedDomain);

    const isValid = formattedDomain.startsWith('http://') || formattedDomain.startsWith('https://');
    onDomainChange(formattedDomain, isValid);
    setIsFocused(false);
  };

  // Set default and error helper texts within the component
  const defaultHelperText = <span>Enter the full Website URL, e.g. <strong>https://www.website.com</strong></span>;
  const errorHelperText = <>
    <span>Enter the full Website URL, e.g. <strong>https://www.website.com</strong></span>
    <span style={{ color: '#DC143C' }}> (valid URLs begin with http:// or https://)</span>
  </>

  return (
    <div className="ComboBox" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <input 
        ref={inputRef}
        type="text" 
        id="domain" 
        value={domain} 
        onChange={handleChange} 
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder=" "
        className={`ComboBox-input ${isFocused || domain ? 'has-content' : ''}`}
      />
      <label 
        htmlFor="domain" 
        className={`ComboBox-label ${isFocused || domain ? 'ComboBox-label--active' : ''}`}
      >
        Website URL*
      </label>
      <div className="ComboBox-helper-text">
        {isDomainValid || !domain ? defaultHelperText : errorHelperText}
      </div>
    </div>
  );
};

export default DomainInput;
