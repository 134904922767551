import React, { useState } from 'react';

const ToggleSwitch = ({ isEncodingEnabled, onToggle }) => {
  return (
    <div className="toggle-switch">
      <input
        id="encodingToggle"
        type="checkbox"
        checked={isEncodingEnabled}
        onChange={onToggle}
        className="toggle-switch-checkbox"
      />
      <label className="toggle-switch-label" htmlFor="encodingToggle">
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};

export default ToggleSwitch;