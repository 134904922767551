import React, { useState, useEffect, useRef } from 'react';

const ComboBox = ({ label, options, onSelect, onAddItem, selectedItems, onRemoveItem, helperText }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [removingTags, setRemovingTags] = useState([]);

  useEffect(() => {
    // Only trigger onSelect when a dropdown option is selected
    if (options.includes(inputValue)) {
      onSelect(inputValue);
      setInputValue('');
    } else {
      setFilteredOptions(options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase())));
    }
  }, [inputValue, options, onSelect]);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setFilteredOptions(options.filter(option => option.toLowerCase().includes(value.toLowerCase())));
  };

  const handleKeyPress = (event) => {
    if ((event.key === 'Enter' || event.key === 'Tab') && filteredOptions.length > 0) {
      const selectedValue = filteredOptions[0];
      setInputValue(selectedValue);
      onSelect(selectedValue);
      setInputValue('');
      event.preventDefault();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setInputValue('');
    setFilteredOptions(options);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleAddNewItem = () => {
    if (inputValue.trim() !== '') {
      onAddItem(inputValue);
      setInputValue('');
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    setRemovingTags(prev => [...prev, itemToRemove]);
    setTimeout(() => {
      onRemoveItem(itemToRemove); // Update the parent state to remove the item
      setRemovingTags(prev => prev.filter(item => item !== itemToRemove));
    }, 300);
  };

  // Also update the onRemoveItem prop function in the parent component accordingly.

  return (
    <div>
      <div className="ComboBox" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <input 
          ref={inputRef}
          type="text" 
          value={inputValue} 
          onChange={handleChange} 
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          list={`${label}-options`} 
          placeholder=" " // Empty placeholder
          className={`ComboBox-input ${isFocused || inputValue ? 'has-content' : ''}`}
        />
        <label className={`ComboBox-label ${isFocused || inputValue ? 'ComboBox-label--active' : ''}`}>
          {label}
        </label>
        <button onClick={handleAddNewItem} style={{ marginLeft: '5px'}} tabIndex={-1}>+</button>
        
      </div>
      <div className="ComboBox-helper-text">
        {helperText}
      </div>
      <datalist id={`${label}-options`}>
        {filteredOptions.map((option, index) => (
          <option key={index} value={option} />
        ))}
      </datalist>
      <div className="ComboBox-items">
      {selectedItems.map((item) => (
        <span
          key={item} // Assuming `item` is a unique string
          className={`tag ${removingTags.includes(item) ? 'tag-removing' : ''}`}
          onClick={() => handleRemoveItem(item)} // Update the handler to work with the item directly
        >
          {item} <span className="tag-close-icon">x</span>
        </span>
      ))}
      </div>
    </div>
  );

};

export default ComboBox;