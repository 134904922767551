import React from 'react';
import '../App.css';
import venmoIcon from '../assets/venmo.png';
import paypalIcon from '../assets/paypal.png';
import bmcIcon from '../assets/buymeacoffee.png';
import kofiIcon from '../assets/ko-fi.png';

const DonationOptions = () => {
  return (
    <div className="donation-button-container">
      <a href="https://account.venmo.com/u/alex-helgeson" target="_blank" rel="noopener noreferrer">
        <button className="donation-button">
          <img src={venmoIcon} alt="Venmo" className="icon venmo-icon" />
        </button>
      </a>
      <a href="https://paypal.me/alexhelgeson" target="_blank" rel="noopener noreferrer">
        <button className="donation-button">
          <img src={paypalIcon} alt="PayPal" className="icon paypal-icon" />
        </button>
      </a>
      <a href="https://buymeacoffee.com/alexhelgeson" target="_blank" rel="noopener noreferrer">
        <button className="donation-button">
          <img src={bmcIcon} alt="Buy Me A Coffee" className="icon bmc-icon" />
        </button>
      </a>
      <a href="https://ko-fi.com/alexhelgeson" target="_blank" rel="noopener noreferrer">
        <button className="donation-button">
          <img src={kofiIcon} alt="Ko-fi" className="icon kofi-icon" />
        </button>
      </a>
    </div>
  );
};

export default DonationOptions;