import React, { createContext, useState, useContext } from 'react';

// Create the context object
const UTMContext = createContext();

// This is the provider component
export const UTMProvider = ({ children }) => {
  // Define the states to be managed globally
  const initialSources = ['google', 'facebook', 'instagram', 'youtube', 'bing', 'twitter', 'x', 'meta', 'linkedin', 'qr code', 'print', 'email'];
  const initialMediums = ['cpc', 'organic', 'display', 'banner', 'newsletter'];

  const [domain, setDomain] = useState('');
  const [isDomainValid, setIsDomainValid] = useState(false);
  const [utmSourceOptions, setUtmSourceOptions] = useState(initialSources);
  const [selectedUtmSources, setSelectedUtmSources] = useState([]);
  const [utmMediumOptions, setUtmMediumOptions] = useState(initialMediums);
  const [selectedUtmMediums, setSelectedUtmMediums] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [campaign, setCampaign] = useState('');
  const [term, setTerm] = useState('');
  const [content, setContent] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [generatedURL, setGeneratedURL] = useState('');
  const [campaignCodes, setCampaignCodes] = useState({});
  const [otherCodes, setOtherCodes] = useState({});
  const [isEncodingEnabled, setIsEncodingEnabled] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [sourceLimit, setSourceLimit] = useState(3);
  const [mediumLimit, setMediumLimit] = useState(3);
  const [customParameters, setCustomParameters] = useState([]);
  const [selectedCustomParameters, setSelectedCustomParameters] = useState([]);

  // The value that will be available to all components in the context
  const value = {
    domain, setDomain,
    isDomainValid,
    setIsDomainValid,
    utmSourceOptions, setUtmSourceOptions,
    selectedUtmSources, setSelectedUtmSources,
    utmMediumOptions, setUtmMediumOptions,
    selectedUtmMediums, setSelectedUtmMediums,
    selectedCampaigns, setSelectedCampaigns,
    selectedContents, setSelectedContents,
    selectedTerms, setSelectedTerms,
    campaign, setCampaign,
    term, setTerm,
    content, setContent,
    additionalInfo, setAdditionalInfo,
    generatedURL, setGeneratedURL,
    campaignCodes, setCampaignCodes,
    otherCodes, setOtherCodes,
    isEncodingEnabled, setIsEncodingEnabled,
    isPremium, setIsPremium,
    sourceLimit, setSourceLimit,
    mediumLimit, setMediumLimit,
    customParameters, setCustomParameters,
    selectedCustomParameters, setSelectedCustomParameters
  };

  return <UTMContext.Provider value={value}>{children}</UTMContext.Provider>;
};

// Custom hook to use the context
export const useUTM = () => useContext(UTMContext);

export default UTMContext;