import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faDownload, faChevronDown, faChevronUp, faCloudUploadAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UTMProvider } from '../contexts/UTMContext';
import { useUTM } from '../contexts/UTMContext'; 

const URLDisplay = ({ url, onCopy }) => {
  const [copySuccess, setCopySuccess] = useState('');
  const [helperText, setHelperText] = useState('');
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'success', or 'failure'
  const [uploadIcon, setUploadIcon] = useState(faCloudUploadAlt);
  const [prevUrl, setPrevUrl] = useState('');
  const [lastUploadedUrl, setLastUploadedUrl] = useState('');
  const [showHelperText, setShowHelperText] = useState(false);

  const {
    domain,
    selectedUtmSources,
    selectedUtmMediums,
    selectedCampaigns, 
    campaignCodes, 
    otherCodes,
    selectedContents, 
    selectedTerms,
    additionalInfo,
    selectedCustomParameters
  } = useUTM();

  const uploadInProgress = useRef(false);

  useEffect(() => {
     if (url && url !== prevUrl) {
       setIsExpanded(true);  // Automatically expand the tray
       setPrevUrl(url);      // Update the prevUrl to current url
     }
   }, [url, prevUrl]);

  const toggleDrawer = () => {
    // If the drawer is currently expanded, we want to remove the scroll immediately
    if (isExpanded) {
      document.querySelector('.url-container').style.overflowY = 'hidden';
    }

    setIsExpanded((prevIsExpanded) => !prevIsExpanded);

    // If the drawer is not expanded, we want to add the scroll after the transition
    if (!isExpanded) {
      setTimeout(() => {
        document.querySelector('.url-container').style.overflowY = 'scroll';
      }, 500); // this should be the duration of your height transition
    }
  };

  useEffect(() => {
     // Reset the flag when URLs change
     uploadInProgress.current = false;

     if (url !== prevUrl) {
       setUploadStatus('idle');
       setUploadDisabled(false);
       setPrevUrl(url);
     }

     // Cleanup function to reset the flag when component unmounts or url changes
     return () => {
       uploadInProgress.current = false;
     };
   }, [url]);

  useEffect(() => {
    let timeoutId;

    // Update the icon based on the upload status
    switch (uploadStatus) {
      case 'success':
        timeoutId = setTimeout(() => {
          if (uploadStatus === 'success') {
            setUploadStatus('complete');
          }
        }, 1000);
        break;
      case 'error':
        setUploadIcon(faTimes);
        break;
      default:
        setUploadIcon(faCloudUploadAlt);
    }

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [uploadStatus]);

  const handleUpload = async () => {
     if (url === lastUploadedUrl) {
       console.log("URLs already uploaded.");
       return;
     }

     setUploadStatus('uploading');
     setUploadDisabled(true);
     uploadInProgress.current = true;

     try {
       await onCopy();
       if (uploadInProgress.current) {
         setUploadStatus('success');
         setLastUploadedUrl(url);
       }
     } catch (error) {
       console.error('Upload failed: ', error);
       if (uploadInProgress.current) {
         setUploadStatus('error');
       }
     } finally {
       if (uploadInProgress.current) {
         setUploadDisabled(false);
       }
       uploadInProgress.current = false;
     }
   };

  const handleCopy = () => {
    if (url !== lastUploadedUrl) {
      handleUpload();
    }

    navigator.clipboard.writeText(url)
      .then(() => {
        setCopySuccess(true);
        setHelperText('URLs copied to clipboard!');
        setShowHelperText(true);
        setTimeout(() => {
          setCopySuccess(false);
          setShowHelperText(false);
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const exportToCSV = () => {
      const urls = url.split('\n');
      const csvRows = [
        'Full URL,Domain,Source,Medium,ID Key,Campaign,Content,Term,Custom Parameters,Additional Information'
      ];

      urls.forEach((singleUrl, index) => {
        const fullUrl = new URL(singleUrl);

        // Using modulo to safely cycle through the list if the list is shorter than the number of URLs
        const source = selectedUtmSources[index % selectedUtmSources.length] || '';
        const medium = selectedUtmMediums[index % selectedUtmMediums.length] || '';
        const campaignName = selectedCampaigns[index % selectedCampaigns.length] || '';
        const campaignCode = campaignCodes[campaignName] || '';
        const otherCode = otherCodes[`${selectedTerms[index % selectedTerms.length] || ''}_${selectedContents[index % selectedContents.length] || ''}_${additionalInfo}`] || '';
        const idKey = `${campaignCode}_${otherCode}`;
        const contentValue = selectedContents[index % selectedContents.length] || '';
        const termValue = selectedTerms[index % selectedTerms.length] || '';

        const customParametersString = selectedCustomParameters
          .map(param => {
            const [key, value] = param.split('=');
            return `${key.trim()}=${value.trim()}`;
          })
          .join('&');

        const additionalInfoValue = additionalInfo || '';

        // Create a row for the CSV
        const row = [
          fullUrl.toString(),
          domain,
          source,
          medium,
          idKey,
          campaignName,
          contentValue,
          termValue,
          customParametersString,
          additionalInfoValue
        ].map(field => `"${field}"`).join(',');

        csvRows.push(row);
      });

      // Generate the CSV content and trigger download
      const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\r\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "utm_urls_export.csv");
      document.body.appendChild(link);
      link.click();

      setDownloadSuccess(true);
      setHelperText('CSV download successful!');
      setShowHelperText(true);

      setTimeout(() => {
        setDownloadSuccess(false);
        setShowHelperText(false);
      }, 2000);
    };

    return (
      <div>
        {url && (
          <>
          <div className="url-display-group">
            <div className="url-display-header" onClick={toggleDrawer} style={{ cursor: 'pointer' }} aria-expanded={isExpanded}>
              <span>URLs are ready for export!</span>
              <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="chevron-icon" />
            </div>
            <div className="button-container">
            <button className={`icon-btn ${copySuccess ? 'success' : ''}`} onClick={handleCopy} title="Copy URL">
              <FontAwesomeIcon icon={faClipboard} className={`icon ${!copySuccess ? 'show-icon' : ''}`} />
              <FontAwesomeIcon icon={faCheck} className={`icon ${copySuccess ? 'show-icon' : ''}`} />
            </button>
            <button className={`icon-btn ${downloadSuccess ? 'success' : ''}`} onClick={exportToCSV} title="Export as CSV">
              <FontAwesomeIcon icon={faDownload} className={`icon ${!downloadSuccess ? 'show-icon' : ''}`} />
              <FontAwesomeIcon icon={faCheck} className={`icon ${downloadSuccess ? 'show-icon' : ''}`} />
            </button>

            {/*

            <button className={`upload-btn ${uploadStatus} ${uploadDisabled ? 'disabled' : ''}`} onClick={handleUpload} title="Upload to Cloud" disabled={uploadStatus === 'complete' || uploadDisabled}>
              <FontAwesomeIcon icon={faCloudUploadAlt} className="icon cloud-icon" />
              <FontAwesomeIcon icon={faCheck} className="icon check-icon" />
              <FontAwesomeIcon icon={faTimes} className="icon error-icon" />
            </button>

            */}
            
            </div>
            </div>
            <div className={`icon-btn-helper-text ${showHelperText ? 'visible' : ''}`}>{helperText}</div>
            <div className={`url-container ${isExpanded ? 'url-container-expand' : 'url-container-collapse'}`}>
              <div className="url-content">
                {url.split('\n').map((singleUrl, index) => (
                  <div key={index}>{singleUrl}</div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
};

export default URLDisplay;
