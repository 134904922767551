import React, { useState, useEffect } from 'react';
import DomainInput from './components/DomainInput';
import URLDisplay from './components/URLDisplay';
import ComboBox from './components/ComboBox';
import ModifiedComboBox from './components/ModifiedComboBox';
import ToggleSwitch from './components/ToggleSwitch';
import SettingsModal from './components/SettingsModal';
import DonationOptions from './components/DonationOptions'; 
import { UTMProvider } from './contexts/UTMContext';
import { useUTM } from './contexts/UTMContext';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const App = () => {
  // Local states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [regenerateFlag, setRegenerateFlag] = useState(false);
  const [lastPostedURLs, setLastPostedURLs] = useState('');
  const [isPosted, setIsPosted] = useState(false);
  const [isTrayOpen, setIsTrayOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [isCustomParamError, setIsCustomParamError] = useState(false);

  // Global states accessed via useUTM
  const {
    domain, setDomain,
    isDomainValid, setIsDomainValid,
    utmSourceOptions, setUtmSourceOptions,
    selectedUtmSources, setSelectedUtmSources,
    utmMediumOptions, setUtmMediumOptions,
    selectedUtmMediums, setSelectedUtmMediums,
    selectedCampaigns, setSelectedCampaigns,
    selectedContents, setSelectedContents,
    selectedTerms, setSelectedTerms,
    campaign, setCampaign,
    term, setTerm,
    content, setContent,
    additionalInfo, setAdditionalInfo,
    generatedURL, setGeneratedURL,
    campaignCodes, setCampaignCodes,
    otherCodes, setOtherCodes,
    isEncodingEnabled, setIsEncodingEnabled,
    isPremium, setIsPremium,
    sourceLimit, setSourceLimit,
    mediumLimit, setMediumLimit,
    customParameters, setCustomParameters,
    selectedCustomParameters, setSelectedCustomParameters
  } = useUTM();



  // useEffect(() => {
  //   fetch('https://script.google.com/macros/s/AKfycbyiwcmGCk4cRMq0HLEVuq1-0GSHby48Oba_G3c38V3-cXnI0domiPycKeACg7ko-HLC/exec')
  //       .then(response => response.json())
  //       .then(data => {
  //         // Skip the first item (column header) and filter out any empty values
  //         const sources = data.sources.slice(1).filter(item => item.trim() !== '');
  //         const mediums = data.mediums.slice(1).filter(item => item.trim() !== '');

  //         setUtmSourceOptions(sources);
  //         setUtmMediumOptions(mediums);

  //         setIsLoading(false);
  //       })
  //           .catch(error => {
  //             console.error('Error fetching data:', error);
  //             setError(error);
  //             setIsLoading(false);
  //           });
  //       }, []);

   useEffect(() => {
    // New useEffect for handling regeneration
      if (regenerateFlag) {
        handleGenerate();
        setRegenerateFlag(false); // Reset the flag
        setIsPosted(false); // Reset the posted flag when new URLs are generated
      }
    }, [regenerateFlag]);

   useEffect(() => {
       let timer;
       if (isCustomParamError) {
         timer = setTimeout(() => {
           setIsCustomParamError(false);
         }, 3000); // Reset the error after 3 seconds
       }

       return () => clearTimeout(timer); // Cleanup the timer on component unmount or error reset
     }, [isCustomParamError]);

  // if (isLoading) {
  //   return (
  //     <div className="loading-container">
  //       <div className="spinner"></div>
  //     </div>
  //   );
  // }

  if (error) {
    return <div>Error: {error.message}</div>; // Display error message
  }

  const handleToggle = () => {
    setIsEncodingEnabled(!isEncodingEnabled);
  };

  const toggleTray = () => {
    setIsTrayOpen(!isTrayOpen);
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleGenerate = () => {
      let urls = [];
      const updatedOtherCodes = { ...otherCodes };

      for (let campaign of selectedCampaigns) {
          let campaignCode = campaignCodes[campaign] || generateCode();
          setCampaignCodes(prev => ({ ...prev, [campaign]: campaignCode }));

          for (let source of selectedUtmSources) {
              for (let medium of selectedUtmMediums) {
                  const contents = selectedContents.length > 0 ? selectedContents : [''];
                  const terms = selectedTerms.length > 0 ? selectedTerms : [''];

                  for (let content of contents) {
                      for (let term of terms) {
                          const currentOtherKey = `${term}_${content}_${additionalInfo}`.trim();
                          if (!updatedOtherCodes[currentOtherKey]) {
                              updatedOtherCodes[currentOtherKey] = generateCode();  // Generates a new code if one does not already exist
                          }
                          generateUrl(source, medium, campaign, campaignCode, content, term, currentOtherKey, updatedOtherCodes[currentOtherKey], urls, isEncodingEnabled);
                      }
                  }
              }
          }
      }

      setOtherCodes(updatedOtherCodes);
      setGeneratedURL(urls.join('\n'));
      setLastPostedURLs('');
      return urls;
  };

  const handleDomainChange = (newDomain, isValid) => {
    setDomain(newDomain);
    setIsDomainValid(isValid);
  };

  const generateUrl = (source, medium, campaign, campaignCode, content, term, currentOtherKey, currentOtherCode, urls, isEncodingEnabled) => {
      let formattedDomain = domain;
      let formattedSource = source.replace(/\s+/g, '_');
      let formattedMedium = medium.replace(/\s+/g, '_');
      let formattedCampaign = campaign.replace(/\s+/g, '_');
      let formattedContent = content.replace(/\s+/g, '_');
      let formattedTerm = term.replace(/\s+/g, '_');

      let queryParams = new URLSearchParams();

      queryParams.append("utm_source", formattedSource);
      queryParams.append("utm_medium", formattedMedium);

      if (!isEncodingEnabled) {
          if (campaign) queryParams.append("utm_campaign", formattedCampaign);
          if (content) queryParams.append("utm_content", formattedContent);
          if (term) queryParams.append("utm_term", formattedTerm);
      } else {
          // Use pre-generated otherCode
          queryParams.append("utm_campaign", `${campaignCode}_${currentOtherCode}`);
      }

      selectedCustomParameters.forEach(param => {
          const [key, value] = param.split('=');
          if (key && value) {
              queryParams.append(key.trim().replace(/\s+/g, '_'), value.trim().replace(/\s+/g, '_'));
          }
      });

      let url = `${formattedDomain}/?${queryParams.toString()}`;
      urls.push(url);
  };

  const generateCode = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleRegenerate = () => {
    // Reset the codes
    setCampaignCodes({});
    setOtherCodes({});

    // Set a flag to indicate regeneration is needed
    setRegenerateFlag(true);
  };

  // const handleDataPost = () => {
  //   if (generatedURL !== lastPostedURLs || !isPosted) {
  //     postUTMDataToSheet(generatedURL);
  //     setLastPostedURLs(generatedURL);
  //     setIsPosted(true);
  //   } else {
  //     console.log("Data already posted.");
  //   }
  // };

  // const postUTMDataToSheet = async (generatedUrls) => {
  //     if (generatedUrls !== lastPostedURLs || !isPosted) {
  //         const urls = generatedUrls.split('\n');
  //         const postData = {
  //             domain: domain,
  //             utmData: urls.map((url, index) => {
  //                 const source = selectedUtmSources[index % selectedUtmSources.length]; // Adjust as necessary
  //                 const medium = selectedUtmMediums[index % selectedUtmMediums.length];
  //                 const campaignName = selectedCampaigns[index % selectedCampaigns.length];
  //                 const campaignCode = campaignCodes[campaignName] || ''; // Ensuring a fallback if no code is found
  //                 const otherCode = otherCodes[`${selectedTerms[index % selectedTerms.length] || ''}_${selectedContents[index % selectedContents.length] || ''}_${additionalInfo}`] || '';
  //                 const contentValue = selectedContents.length > 0 ? selectedContents[0] : ''; // Adjust if multiple contents
  //                 const termValue = selectedTerms.length > 0 ? selectedTerms[0] : ''; // Adjust if multiple terms
  //                 const customParametersString = selectedCustomParameters
  //                     .map(param => {
  //                         const [key, value] = param.split('=');
  //                         return `${key.trim()}=${value.trim()}`;
  //                     })
  //                     .join('&');

  //                 let idKey = `${campaignCode}_${otherCode}`; // Concatenate campaignCode and otherCode

  //                 return {
  //                     fullUrl: url, // Include the full URL as the first item
  //                     source,
  //                     medium,
  //                     campaign: campaignName,
  //                     campaignCode, // Always include the campaign code
  //                     content: contentValue,
  //                     term: termValue,
  //                     additionalInfo: additionalInfo || '',
  //                     customParameters: customParametersString,
  //                     idKey
  //                 };
  //             }),
  //         };

  //         try {
  //             const response = await fetch('https://script.google.com/macros/s/AKfycbzhrO5h5LciDHz2V02rmxJZZoRvSGhA-7K-61AgZ6f3L1lg38mU_h5X0LkOjd4jtB0_bg/exec', {
  //                 method: 'POST',
  //                 body: JSON.stringify(postData),
  //                 headers: { 'Content-Type': 'text/plain' }
  //             });

  //             if (!response.ok) {
  //                 throw new Error('Network response was not ok');
  //             }

  //             const responseData = await response.json();
  //             console.log('Success:', responseData);

  //             setLastPostedURLs(generatedUrls);
  //             setIsPosted(true);
  //         } catch (error) {
  //             console.error('Error:', error);
  //         }
  //     } else {
  //         console.log("Data already posted. Copied to clipboard.");
  //     }
  // };

  const handleAddUtmSource = (newItem) => {
    if (newItem && !utmSourceOptions.includes(newItem)) {
      setUtmSourceOptions([...utmSourceOptions, newItem]);
    }
    handleSelectUtmSource(newItem);
  };

  const handleAddUtmMedium = (newItem) => {
    if (newItem && !utmMediumOptions.includes(newItem)) {
      setUtmMediumOptions([...utmMediumOptions, newItem]);
    }
    handleSelectUtmMedium(newItem);
  };

  const handleAddUtmCampaign = (item) => {
    const trimmedItem = item.trim();
    if (trimmedItem && !selectedCampaigns.includes(trimmedItem)) {
      if (selectedCampaigns.length < 3) {
        setSelectedCampaigns([...selectedCampaigns, trimmedItem]);
      } else {
        // Notify user that the limit has been reached
        alert("You can select up to 3 campaigns.");
      }
    }
  };

  const handleAddUtmContent = (item) => {
    const trimmedItem = item.trim();
    if (trimmedItem && !selectedContents.includes(trimmedItem)) {
      setSelectedContents([trimmedItem]); // Replace any existing content
    }
  };

  const handleAddUtmTerm = (item) => {
    const trimmedItem = item.trim();
    if (trimmedItem && !selectedTerms.includes(trimmedItem)) {
      setSelectedTerms([trimmedItem]); // Replace any existing term
    }
  };

  const handleSelectUtmSource = (item) => {
    if (!selectedUtmSources.includes(item)) {
      if (selectedUtmSources.length < sourceLimit) {
        setSelectedUtmSources([...selectedUtmSources, item]);
        // Remove the selected item from the options list
        setUtmSourceOptions(utmSourceOptions.filter(option => option !== item));
      } else {
        // Dynamically include the sourceLimit in the alert message
        alert(`You can only select up to ${sourceLimit} sources.`);
      }
    }
  };

  const handleSelectUtmMedium = (item) => {
    if (!selectedUtmMediums.includes(item)) {
      if (selectedUtmMediums.length < mediumLimit) {
        setSelectedUtmMediums([...selectedUtmMediums, item]);
        // Remove the selected item from the options list
        setUtmMediumOptions(utmMediumOptions.filter(option => option !== item));
      } else {
        // Dynamically include the mediumLimit in the alert message
        alert(`You can only select up to ${mediumLimit} mediums.`);
      }
    }
  };

  const handleRemoveUtmSource = (sourceToRemove) => {
    setSelectedUtmSources(selectedUtmSources.filter(source => source !== sourceToRemove));
    // Optionally add back the removed item to the options list
    setUtmSourceOptions([...utmSourceOptions, sourceToRemove]);
  };

  const handleRemoveUtmMedium = (mediumToRemove) => {
    setSelectedUtmMediums(selectedUtmMediums.filter(medium => medium !== mediumToRemove));
    // Optionally add back the removed item to the options list
    setUtmMediumOptions([...utmMediumOptions, mediumToRemove]);
  };

  const handleRemoveUtmCampaign = (campaignToRemove) => {
    setSelectedCampaigns(selectedCampaigns.filter(campaign => campaign !== campaignToRemove));
  };

  const handleRemoveUtmContent = (contentToRemove) => {
    setSelectedContents(selectedContents.filter(content => content !== contentToRemove));
  };

  const handleRemoveUtmTerm = (termToRemove) => {
    setSelectedTerms(selectedTerms.filter(term => term !== termToRemove));
  };

  const handleAddCustomParameter = (parameter) => {
    if (selectedCustomParameters.length < 3) {
      if (parameter && parameter.includes('=') && !selectedCustomParameters.includes(parameter)) {
        setSelectedCustomParameters([...selectedCustomParameters, parameter]);
        setIsCustomParamError(false); // Reset error on successful add
      } else { 
        setIsCustomParamError(true); // Set error when input is invalid
      }
    } else {
      alert("You can add up to 3 custom parameters.");
      setIsCustomParamError(false); // Assuming no error state is necessary when limit is reached
    }
  };

  const handleRemoveCustomParameter = (parameterToRemove) => {
    setSelectedCustomParameters(selectedCustomParameters.filter(param => param !== parameterToRemove));
  };

  const handleAddAdditionalInfo = (info) => {
    const trimmedInfo = info.trim();
    if (trimmedInfo) { // Only set if the trimmed info is not empty
      setAdditionalInfo(trimmedInfo);
    }
  };

  const handleRemoveAdditionalInfo = () => {
    setAdditionalInfo(''); // Clears the additional info
  };

  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(prev => !prev);
  };

const isGenerateDisabled = !isDomainValid || selectedUtmSources.length === 0 || selectedUtmMediums.length === 0 || selectedCampaigns.length === 0;
const generateButtonText = isGenerateDisabled ? "Please ensure all required fields are filled correctly. Required fields are denoted by an asterisk*" : "";

  return (
    <div className="App">
      <header className="app-header">
        <h1>The UTM Builder</h1>

        {/* 
        <button onClick={toggleSettings} className="gear-icon-button">
          ⚙️
        </button>
        {isSettingsOpen && <SettingsModal 
          isPremium={isPremium} 
          setIsPremium={setIsPremium} 
          sourceLimit={sourceLimit} 
          setSourceLimit={setSourceLimit} 
          mediumLimit={mediumLimit} 
          setMediumLimit={setMediumLimit} 
          utmSourceOptions={utmSourceOptions} 
          setUtmSourceOptions={setUtmSourceOptions} 
          utmMediumOptions={utmMediumOptions} 
          setUtmMediumOptions={setUtmMediumOptions} 
          onClose={toggleSettings} 
        />} 
        */}

      </header>

      <div className ="content-container">
      <div className="left-column">

      <div className="encode-toggle-container">
        <div className="encode-info">
          <span className="encode-helper-text">Encode URLs</span>
          <div className="question-mark-container">
            <span className="question-mark">?</span>
            <div className="speech-bubble">
              The UTM Builder can encode parameters, allowing virtually limitless information to be associated and stored within your UTM Campaign parameter. 
              <br/><br/>
              This feature is only recommended for advanced users.
              <br/><br/>
              <a href="https://carefreeanalytics.com" target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '0px' }}>Learn more here!</a>
            </div>
          </div>
        </div>
        <ToggleSwitch isEncodingEnabled={isEncodingEnabled} onToggle={handleToggle} />
      </div>

      <DomainInput onDomainChange={handleDomainChange} helperText=<span>Enter the full Website URL e.g. <strong>https://www.website.com</strong></span> />

      <ComboBox 
        label="UTM Source*"
        options={utmSourceOptions}
        onSelect={handleSelectUtmSource}
        onAddItem={handleAddUtmSource}
        onRemoveItem={handleRemoveUtmSource}
        selectedItems={selectedUtmSources}
        helperText=<span>Enter the Sources e.g. <strong>google, facebook, news...</strong></span>
      />
      <ComboBox 
        label="UTM Medium*"
        options={utmMediumOptions}
        onSelect={handleSelectUtmMedium}
        onAddItem={handleAddUtmMedium}
        onRemoveItem={handleRemoveUtmMedium}
        selectedItems={selectedUtmMediums}
        helperText=<span>Enter the Mediums e.g. <strong>cpc, banner, email...</strong></span>
      />
      <ModifiedComboBox
        label="UTM Campaign*"
        onSelect={handleAddUtmCampaign}
        selectedItems={selectedCampaigns}
        onRemoveItem={handleRemoveUtmCampaign}
        helperText=<span>Enter the Campaigns e.g. <strong>winter 2024, summer sale...</strong></span>
      />
      <ModifiedComboBox
        label="UTM Content"
        onSelect={handleAddUtmContent}
        selectedItems={selectedContents}
        onRemoveItem={handleRemoveUtmContent}
        helperText=<span>Enter the Content fields e.g. <strong>ad style a, ad format b...</strong></span>
      />
      <ModifiedComboBox
        label="UTM Term"
        onSelect={handleAddUtmTerm}
        selectedItems={selectedTerms}
        onRemoveItem={handleRemoveUtmTerm}
        helperText=<span>Enter the Term fields e.g. <strong>keyword 1, keyword 2...</strong></span>
      />

      <div className="advanced-options-toggle">
      <h2 onClick={toggleAdvancedOptions}>
          Advanced Options <FontAwesomeIcon icon={showAdvancedOptions ? faChevronUp : faChevronDown} className="chevron-icon" />
      </h2>
      </div>
      {showAdvancedOptions && (
        <div className="advanced-options-content">
        <ModifiedComboBox
          label="Custom Parameters"
          onSelect={handleAddCustomParameter}
          selectedItems={selectedCustomParameters}
          onRemoveItem={handleRemoveCustomParameter}
          helperText={isCustomParamError ? 
            <>
              <span>Custom parameters will be included at the end of generated URLs.</span>
              <span style={{ color: '#DC143C' }}> These must be complete and precise, e.g. <strong>ref=affiliate_partner</strong></span>
            </> :
            <span>Custom parameters will be included at the end of generated URLs. These must be complete and precise, e.g. <strong>ref=affiliate_partner</strong></span>
          }
        />
          <ModifiedComboBox
            label="Additional Information"
            onSelect={handleAddAdditionalInfo}
            selectedItems={additionalInfo ? [additionalInfo] : []} // Only add to list if non-empty
            onRemoveItem={handleRemoveAdditionalInfo}
            helperText="Add as much additional information here as you want. This will not be visible in the URLs, but will be available via export."

            // helperText={isEncodingEnabled
            //   ? "Add as much additional information here as you want. This will not be visible in the URLs, but will be available via export or cloud storage."
            //   : "This feature is only available when <strong>encoding</strong> is enabled"}
            //disabled={!isEncodingEnabled}

          />
        </div>
      )}
      </div>

      <div className="right-column">
      <div className="button-container">
        <button className="generate-btn" onClick={handleGenerate} disabled={isGenerateDisabled}>Generate URLs</button>
        <button className="generate-btn" onClick={handleRegenerate} disabled={isGenerateDisabled} title="Regenerate Codes">
          <FontAwesomeIcon icon={faRecycle} />
        </button>
      </div>
      <div className={`Generate-helper-text ${error ? 'error-text' : ''}`} dangerouslySetInnerHTML={{ __html: generateButtonText }} />
      <URLDisplay 
        url={generatedURL} 
        // onCopy={handleDataPost} 
        selectedCampaigns={selectedCampaigns}
        campaignCodes={campaignCodes}
        otherCodes={otherCodes}
        content={content}
        term={term}
        additionalInfo={additionalInfo}
        selectedCustomParameters={selectedCustomParameters}
      />
    </div>
    </div>
    <h3>Love The UTM Builder? Support the developer!</h3>
        <DonationOptions />
    </div>
  );
};

export default App;
