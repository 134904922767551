import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UTMProvider } from './contexts/UTMContext';  // Import the UTMProvider
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UTMProvider>  
      <App />
    </UTMProvider>
  </React.StrictMode>
);

reportWebVitals();
