import React, { useState, useEffect, useRef } from 'react';

const ModifiedComboBox = ({ label, onSelect, selectedItems, onRemoveItem, helperText, disabled }) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [removingTags, setRemovingTags] = useState([]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddNewItem = () => {
    const trimmedInput = inputValue.trim();
    if (disabled || !trimmedInput) return; // Prevent adding when disabled or input is empty/whitespace
    onSelect(trimmedInput);
    setInputValue('');
  };

  const handleKeyPress = (event) => {
    if (disabled) return; // Prevent input when disabled
    if (event.key === 'Enter') {
      const trimmedInput = inputValue.trim();
      if (!trimmedInput) {
        event.preventDefault(); // Stop form submission and do not add empty input
        return;
      }
      onSelect(trimmedInput);
      setInputValue('');
      event.preventDefault(); // Prevent form submission if this is inside a form
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setInputValue('');
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleRemoveItem = (itemToRemove) => {
    setRemovingTags(prev => [...prev, itemToRemove]);
    setTimeout(() => {
      onRemoveItem(itemToRemove); // Update the parent state to remove the item
      setRemovingTags(prev => prev.filter(item => item !== itemToRemove));
    }, 300);
  };

  // Inside your ModifiedComboBox component
  return (
    <div>
      <div className="ComboBox" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <input 
          ref={inputRef}
          type="text" 
          value={inputValue} 
          onChange={handleChange} 
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder=" "
          className={`ComboBox-input ${isFocused || inputValue ? 'has-content' : ''} ${disabled ? 'disabled' : ''}`}
          disabled={disabled}
        />
        <label className={`ComboBox-label ${isFocused || inputValue ? 'ComboBox-label--active' : ''} ${disabled ? 'disabled' : ''}`}>
          {label}
        </label>
        <button onClick={handleAddNewItem} className={`${disabled ? 'disabled' : ''}`} style={{ marginLeft: '5px'}} tabIndex={-1} disabled={disabled}>
          +
        </button>
      </div>
      <div className="ComboBox-helper-text">
        {helperText}
      </div>
      <div className="ComboBox-items">
        {selectedItems.map((item) => (
          <span
            key={item}
            className={`tag ${removingTags.includes(item) ? 'tag-removing' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={() => !disabled && handleRemoveItem(item)}
          >
            {item} <span className="tag-close-icon">x</span>
          </span>
        ))}
      </div>
    </div>
  );

  };

export default ModifiedComboBox;